import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Layout'
import { StyledLink } from '../components/Buttons/Button'
import SocialShare from '../components/SocialShare/SocialShare'
import Seo from 'gatsby-plugin-wpgraphql-seo';


const PressRelease = ({data}) => {
  const {
    title,
    content,
    date,
    pressRelease: { location, secondaryTitle}
  } = data.wpPressrelease


  return (
    <Layout title={title}>
      <Seo post={data.wpPressrelease} />
      <div className="container">
        <div className="md:grid md:grid-cols-4 md:gap-8">
          <header className="mt-6 mb-0 col-span-3">
            <StyledLink noOutline arrow="left" to="/press-releases/">BACK TO PRESS RELEASES</StyledLink>
            <h1 className="mt-6">{title}</h1>
            {secondaryTitle && (
              <h3 className="font-normal" dangerouslySetInnerHTML={{__html: secondaryTitle}}/>
            )}
          </header>
          <article className="col-span-3">
            <h3 className="uppercase mb-12">{location && `${location}, `}{date}</h3>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
          </article>
          <footer className="col-span-4 my-12">
            <h3>SHARE THIS PRESS RELEASE</h3>
            <SocialShare title={title} />
          </footer>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpPressrelease(id: { eq: $id }) {
      id
      date(formatString: "MMMM DD, YYYY")
      title
      content
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      pressRelease {
        location
        secondaryTitle
      }
    }
  }
`

export default PressRelease
