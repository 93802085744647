import React from 'react'
import { ReactComponent as TwIcon } from '../../images/social/twitter.svg'
import { ReactComponent as FbIcon } from '../../images/social/facebook.svg'
import { ReactComponent as WaIcon } from '../../images/social/whatsapp.svg'
import { ReactComponent as LiIcon } from '../../images/social/linkedin.svg'
import { ReactComponent as MailIcon } from '../../images/social/email.svg'

import './SocialShare.scss'

const ShareIcon = ({social, url, children}) => {

  return (
    <li className={`share-icon ${social}`}>
      <a className="block py-6 border-r text-center hover:bg-indigo hover:text-white" target="_blank" href={url}>
        {children}
      </a>
    </li>
  )
}

const SocialShare = ({title}) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const fbUrl = `https://www.facebook.com/sharer.php?u=${url}`
  const twUrl = `https://twitter.com/share?text=${title}&url=${url}`
  const waUrl = `https://api.whatsapp.com/send?text=${url}`
  const liUrl = `https://linkedin.com/shareArticle?mini=true&title=${title}&url=${url}`
  const mailUrl = `mailto:?subject=${title}&body=${url}`


  return (
    <div>
      <ul className="list-none m-0 p-0 flex border w-full rounded">
        <ShareIcon social="facebook" url={fbUrl}><FbIcon /></ShareIcon>
        <ShareIcon social="twitter" url={twUrl}><TwIcon /></ShareIcon>
        <ShareIcon social="whatsapp" url={waUrl}><WaIcon /></ShareIcon>
        <ShareIcon social="linkedin" url={liUrl}><LiIcon /></ShareIcon>
        <ShareIcon social="twitter" url={mailUrl}><MailIcon /></ShareIcon>
      </ul>
    </div>
  )
}

export default SocialShare
